import Header from '@/modules/home/Header';
import {
  Flex,
  Box,
  Button,
  Heading,
  ButtonGroup,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ImageTextSection from '@/modules/home/ImageTextSection';
import YoutubePlayer from '@/common/components/YoutubePlayer';

const Home: NextPage = () => {
  const router = useRouter();
  const showGoose = useBreakpointValue({
    base: false,
    xl: true,
  });

  return (
    <Box>
      <Head>
        <title>GooseClip</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Box width="100%" pb={20}>
        <Header />

        <Flex>
          <Box
            paddingX={{ base: 5, md: '10%', xl: '30%' }}
            paddingY={10}
            textAlign="center"
          >
            <Heading
              variant="headline2"
              marginBottom={7}
              textAlign="center"
              paddingX={{ base: 13, lg: '15%', xl: 16 }}
            >
              A new generation of products
            </Heading>

            <Heading
              variant="headline6"
              paddingX={{ base: '5%', lg: '50px' }}
              marginBottom={16}
              whiteSpace="pre-wrap"
            >
              With gaming, crypto, web3, 5G, remote working, the world is on the
              verge of an explosive peer-to-peer revolution and GooseClip will
              be the fuse.
            </Heading>

            <ButtonGroup spacing={6} size="lg">
              <Button
                onClick={() => router.push('/company')}
                minW={{ base: '146px', md: '196px' }}
              >
                About
              </Button>
              <Button
                onClick={() => router.push('/link')}
                minW={{ base: '146px', md: '196px' }}
                disabled={true}
              >
                Try GooseLink
              </Button>
            </ButtonGroup>
          </Box>
          {showGoose ? (
            <Image
              src="/gooseDark.png"
              alt="Gooseclip mascot"
              boxSize="420px"
              position="absolute"
              right="80px"
              top="30px"
            />
          ) : null}
        </Flex>
      </Box>

      <ImageTextSection
        bg="black"
        // imageContent={<YoutubePlayer embedId="XBK-GPFIE3k?start=360" />}
        imagePosition="right"
        title={
          <>
            Goose
            <Box as="span" color="primary.300">
              Link
            </Box>
          </>
        }
        description={`Link your users directly with each other utilizing the might of peer-to-peer technology. Peer-to-peer is often preventatively complex for companies to implement and as such we see a limited number of products in the market who utilize this powerful technology. Blow your competition out of the water with cheaper, faster, more secure products without the hefty development costs. 
        
GooseLink exposes a simple to use, 2 route websocket API, handling all the complexities of establishing connections between users so you can focus on building what is important.
        
Currently in Alpha, contact us if you wish to know more.`}
      />

      <ImageTextSection
        imageContent={
          <Image src="/critterflick.png" alt="critterflick demo" maxH="348px" />
        }
        imagePosition="left"
        title={
          <>
            Goose
            <Box as="span" color="lightAccent.300">
              Play
            </Box>
          </>
        }
        description={`Play together in a few simple steps using our Unity plugin. 
Multiplayer networking for games is excessively difficult to implement, often requiring an almost absolute refactor of an already functional singleplayer game. 

GoosePlay is first and foremost designed to be intuitive, no complex knowledge of multiplayer game design required. After setup, simply annotate your methods with the [Linked] attribute and our code-weaving system will do the rest.

Currently in Alpha, contact us if you wish to know more.`}
      />

      <ImageTextSection
        bg="gray.800"
        // imageContent={<YoutubePlayer embedId="XBK-GPFIE3k?start=360" />}
        imagePosition="right"
        title={
          <>
            Goose
            <Box as="span" color="gray.400">
              Clip
            </Box>
          </>
        }
        description={`Our namesake, GooseClip synchronizes your clipboard across your devices, optimizing your workflows by avoiding unnecessary context switching. 
        
Fast, end-to-end encrypted transfers with no size limits.
    
Currently in Alpha, contact us if you wish to know more.`}
      />
    </Box>
  );
};

export default Home;
