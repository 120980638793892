import {
  Box,
  Flex,
  IconButton,
  Icon,
  Image,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdAccountCircle } from 'react-icons/md';
import NextLink from 'next/link';
import { PropsWithChildren } from 'react';

interface HeaderProps {
  rightAdornment?: React.ReactElement;
  logoVariant?: 'light' | 'dark';
}

export default function Header({
  rightAdornment,
  children,
  logoVariant = 'light',
}: PropsWithChildren<HeaderProps>) {
  const isSmall = useBreakpointValue({
    base: true,
    lg: false,
  });

  const logoWidth = useBreakpointValue({
    base: '28px',
    lg: '178.25px',
  });
  const logoHeight = useBreakpointValue({
    base: '28px',
    lg: '30px',
  });

  return (
    <Flex
      paddingY={14}
      paddingX={{ base: 12, md: 20, lg: 36 }}
      as="header"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Box>
          <NextLink href="/" passHref>
            <Link>
              {logoVariant === 'light' ? (
                <GooseClipIcon
                  width={logoWidth}
                  height={logoHeight}
                  isSmall={isSmall}
                />
              ) : (
                <GooseClipIconDark
                  width={logoWidth}
                  height={logoHeight}
                  isSmall={isSmall}
                />
              )}
            </Link>
          </NextLink>
        </Box>
      </Flex>

      <Box>{rightAdornment}</Box>
    </Flex>
  );
}

const GooseClipIcon = ({
  width,
  height,
  isSmall,
}: {
  width?: string;
  height?: string;
  isSmall?: boolean;
}) => {
  if (isSmall) {
    return (
      <Image
        src="/gooseClipIcon.svg"
        alt="GooseClip Logo"
        width={width}
        height={height}
      />
    );
  }
  return (
    <Image
      src="/gooseClipLogo.svg"
      alt="GooseClip Logo"
      width={width ?? '178.25px'}
      height={height ?? '30px'}
    />
  );
};

const GooseClipIconDark = ({
  width,
  height,
  isSmall,
}: {
  width?: string;
  height?: string;
  isSmall?: boolean;
}) => {
  if (isSmall) {
    return (
      <Image
        src="/gooseClipIconDark.svg"
        alt="GooseClip Logo"
        width={width}
        height={height}
      />
    );
  }
  return (
    <Image
      src="/gooseClipLogoDark.svg"
      alt="GooseClip Logo"
      width={width ?? '178.25px'}
      height={height ?? '30px'}
    />
  );
};
