import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Grid,
  GridItem,
  BoxProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ImageTextSectionProps {
  imagePosition: 'left' | 'right';
  imageContent?: ReactNode;
  title: ReactNode;
  description: string;
  bg?: string;
  containerProps?: BoxProps;
}
export default function ImageTextSection({
  imagePosition = 'right',
  bg,
  title,
  description,
  imageContent,
}: ImageTextSectionProps) {
  const isVerticalOrientation = useBreakpointValue({ base: true, lg: false });

  const textContent = (
    <Box maxWidth="686px">
      <Heading variant="headline3" mb={10} style={{ whiteSpace: 'pre-wrap' }}>
        {title}
      </Heading>
      <Box>
        <Text variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
          {description}
        </Text>
      </Box>
    </Box>
  );

  return (
    <Box
      bg={bg}
      paddingY={{ base: 20, lg: 32 }}
      paddingX={{ base: 12, md: 20, lg: 36 }}
    >
      <Grid
        justifyContent="center"
        templateColumns={{
          base: '1fr',
          lg: 'repeat(2, 1fr)',
        }}
        columnGap={16}
        gap={5}
      >
        <GridItem>
          {imagePosition === 'left' || isVerticalOrientation
            ? imageContent
            : textContent}
        </GridItem>
        <GridItem>
          {imagePosition === 'left' || isVerticalOrientation
            ? textContent
            : imageContent}
        </GridItem>
      </Grid>
    </Box>
  );
}
